import React, { FunctionComponent, useEffect, useRef } from 'react';

import styles from './index.module.scss';

const fps = 60;
const fpsInterval = 1000 / fps;
let then = null;

type Props = {
  className?: string;
  partOfSimilarList?: boolean;
  children;
};

type StyleProps = { backgroundColor?; backgroundImage? };

const Plug: FunctionComponent<Props> = ({ children }) => {
  const { props } = children;
  const bgColor =
    props?.plug?.backgroundColor?.backgroundColor || props?.backgroundColor?.backgroundColor;
  const bgImage = props?.plug?.backgroundImage?.backgroundImage?.asset?.url;
  const elementRef = useRef(null);
  const animationRef = useRef<number | null>(null);
  const scrollRef = useRef({ scrollPos: 0, scrollTo: 0, speed: 15 });

  const style: StyleProps = {};

  if (bgColor) {
    style.backgroundColor = bgColor;
  }

  if (bgImage) {
    style.backgroundImage = `url(${bgImage}`;
  }

  useEffect(() => {
    const anim = (now: number): void => {
      if (!then) {
        then = now;
      }

      const elapsed = now - then;

      const sp = document.documentElement.scrollTop;

      if (elapsed > fpsInterval && scrollRef.current.scrollPos != sp) {
        scrollRef.current.scrollTo = sp;

        const th = document.documentElement.clientHeight;

        scrollRef.current.scrollPos +=
          (scrollRef.current.scrollTo - scrollRef.current.scrollPos) / scrollRef.current.speed;

        const p = (scrollRef.current.scrollPos / th) * 1.5;

        then = now - (elapsed % fpsInterval);

        if (
          elementRef.current &&
          Math.abs(scrollRef.current.scrollPos - scrollRef.current.scrollTo) > 0.0015
        ) {
          elementRef.current.style.backgroundPosition = `center ${Math.max(-1, -10 + 10 * p)}%`;
        }
      }

      animationRef.current = requestAnimationFrame(anim);
    };
    if (bgImage) {
      animationRef.current = requestAnimationFrame(anim);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  return (
    <div ref={elementRef} style={style} className={`plug ${styles.root} ${props._type || ''}`}>
      <div className={'SC__content-container-gutter-wrapper'}>
        <div className={`SC__content-container`}>{children}</div>
      </div>
    </div>
  );
};

export default Plug;
