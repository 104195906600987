import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  color?: string;
  text?: string;
  onClick?: () => void;
  active?: boolean;
  icon?: any;
};

const PillButton: FunctionComponent<Props> = ({
  text = null,
  onClick = null,
  active = false,
  icon = false,
}) => {
  return (
    <button
      className={`${styles.root} ${active ? styles.active : styles.inactive}`}
      onClick={onClick || null}>
      {icon && icon}
      {text}
    </button>
  );
};

export default React.memo(PillButton);
